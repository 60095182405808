import moment from 'moment';
import twitter from 'twitter-text';

// Remove mention syntax from string
export function bareCopy(copy) {
  if (!copy) { return ""; }
  return copy.replace(/@\[([^\]]+)\]\((\d+)\)/g, '$1');
}

export function copyLength(copy) {
  if (!copy) { return 0; }
  return bareCopy(copy).length;
}

export function copyLengthForTwitter(copy) {
  if (!copy) { return 0; }
  return twitter.parseTweet(bareCopy(copy)).weightedLength;
}

export function hashtagsCount(copy) {
  if (!copy) { return 0; }
  return (bareCopy(copy).match(/(^|\W)(#[a-zA-Z\d][\w-]*)/g) || []).length;
}

export function dateifyString(string) {
  if (typeof string === "string") {
    return moment.parseZone(string);
  }

  return string;
}

export function capitalize(string) {
  if (!string) { return null; }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleize(string) {
  return string.split(/[ _]/).map((piece)=> capitalize(piece)).join(" ");
}

export function classify(string) {
  return string.split(/[ _]/).map((piece)=> capitalize(piece)).join("");
}

export function camelize(string) {
  const s = classify(string);
  return s && s[0].toLowerCase() + s.slice(1);
}

export function pluralize(singularString, count, pluralString = null) {
  if (count == 1) {
    return singularString;
  } else {
    return pluralString ? pluralString : `${singularString}s`
  }
}

export function pluralizeUnlimited(singularString, count, capitalized = false, pluralString = null) {
  if (count > 500) {
    return capitalized ? "Unlimited " + (pluralString ? pluralString : `${singularString}s`) : "unlimited " + (pluralString ? pluralString : `${singularString}s`)
  } else {
    return pluralize(singularString, count, pluralString);
  }
}

export function dasherize(string) {
  if (!string) { return; }
  return string.replace(/\_/g, '-');
}

export function underscore(string) {
  if (!string) { return; }
  return string.replace( /([A-Z])/g, " $1" ).split(' ').join('_').toLowerCase().replace(/^\_/, "");
}

export function truncate(string, truncateAt) {
  if (!string || (string.length <= truncateAt)) {
    return string;
  }

  const lengthOfString = truncateAt - 3;
  return string.substring(0, lengthOfString) + "...";
}

export function arrayToSentence(array) {
  if (!array) { return ""; }

  let arrayCopy = [...array];
  let lastWord = "";

  if (arrayCopy.length > 1) {
    lastWord = " and " + arrayCopy.pop();

    if (arrayCopy.length > 1){
      lastWord = "," + lastWord;
    }
  }

  return arrayCopy.join(", ") + lastWord;
}

export function regExpEscape(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function insertIntoString(string, value, start = null, end = null) {
  if (start != null && end != null) {
    return string.substring(0, start) + value + string.substring(end, string.length);
  } else {
    return string + value;
  }
}

export function convertNewLinesToBr(string) {
  return string.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export function removeNewLines(string) {
  return string.replace(/(?:\r\n|\r|\n)/g, ' ');
}

export function emojiForCountryCode(code) {
  // https://andycroll.com/ruby/convert-iso-country-code-to-emoji-flag/
  const codePoints = code.split('').map(c =>  127397 + c.charCodeAt());
  const emoji = String.fromCodePoint(...codePoints);

  return emoji;
}

export function replaceVariables(string, variables) {
  return string.replace(/\{\{[\s]*([a-zA-Z0-9\_\-]+)[\s]*\}\}/g, (matched)=> {
    const name = matched.replace("{{", "").replace("}}", "").trim();
    return variables[name] || "";
  });
}
